<template>
  <div class="w-full lg:ml-12">
    <div>
      <user-header></user-header>
    </div>
    <div class="bg-basebackground sticky pt-2 lg:pt-0 top-10 lg:top-0 z-30">
      <div>
        <div class="text-lg lg:text-base">
          <m-menu-panel>
            <div class="flex justify-between">
              <div class="flex">
                <div>
                  <m-radio
                    filterName="byDate"
                    :value="groupBy"
                    @change="changeGroupBy"
                    segmentName="Action group"
                  >
                    <span title="Group actions by due date"
                      ><span class="hidden lg:inline">By due d</span
                      ><span class="inline lg:hidden">D</span>ate</span
                    >
                  </m-radio>
                </div>
                <div>
                  <m-radio
                    filterName="byMeeting"
                    :value="groupBy"
                    @change="changeGroupBy"
                    segmentName="Action group"
                  >
                    <span title="Group actions by meeting">Meeting</span>
                  </m-radio>
                </div>
                <div>
                  <m-radio
                    filterName="byOwner"
                    :value="groupBy"
                    @change="changeGroupBy"
                    segmentName="Action group"
                  >
                    <span title="Group actions by owner">Owner</span>
                  </m-radio>
                </div>
              </div>
              <div
                class="flex lg:hidden cursor-pointer mr-8"
                @click="toggleFilters"
              >
                <div class="mt-0.5 lg:mt-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="margin-top: 0.1rem;"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#00A4BD"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="14" cy="6" r="2" />
                    <line x1="4" y1="6" x2="12" y2="6" />
                    <line x1="16" y1="6" x2="20" y2="6" />
                    <circle cx="8" cy="12" r="2" />
                    <line x1="4" y1="12" x2="6" y2="12" />
                    <line x1="10" y1="12" x2="20" y2="12" />
                    <circle cx="17" cy="18" r="2" />
                    <line x1="4" y1="18" x2="15" y2="18" />
                    <line x1="19" y1="18" x2="20" y2="18" />
                  </svg>
                </div>
                <div
                  class="text-grey3 font-medium ml-2 dark:hover:text-white"
                  title="Get to your next meeting"
                >
                  Filters
                </div>
              </div>
            </div>
            <div
              :class="
                filterHidden + ' lg:flex mt-4 lg:mt-0 mr-8 lg:mr-0 justify-end'
              "
            >
              <div>
                <m-checkbox
                  v-bind:value="filterOwn"
                  @input="changeFilter('own', $event)"
                  v-model="filterOwn"
                  segmentName="Action filter"
                  segmentLabel="Mine"
                >
                  <span class="ml-2 mr-2" title="Show my actions">Mine</span>
                </m-checkbox>
              </div>
              <div>
                <m-checkbox
                  v-bind:value="filterOthers"
                  @input="changeFilter('others', $event)"
                  v-model="filterOthers"
                  class="ml-2"
                  segmentName="Action filter"
                  segmentLabel="Others"
                >
                  <span class="ml-2 mr-2" title="Show others' actions"
                    >Others</span
                  >
                </m-checkbox>
              </div>
              <div>
                <m-checkbox
                  v-bind:value="filterCompleted"
                  @input="changeFilter('completed', $event)"
                  v-model="filterCompleted"
                  class="ml-2"
                  segmentName="Action filter"
                  segmentLabel="Done"
                >
                  <span class="ml-2" title="Show completed actions">Done</span>
                </m-checkbox>
              </div>
            </div>
          </m-menu-panel>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <template v-if="defer(1)">
        <template v-if="!eventDetailsFetched">
          <!-- START Loading effect -->
          <div v-for="i in 2" :key="i" class="w-4/5 m-auto">
            <div>
              <m-placeholder class="mt-6 h-6 rounded-lg my-auto w-1/3">
              </m-placeholder>
            </div>
            <hr
              class="mt-3 mb-4 border-solid border-l-0 border-r-0 border-b-0"
              style="border-color: rgba(0, 164, 189, 0.08);"
            />

            <!-- loader 1  -->
            <div
              class="flex -ml-3 pl-3 -mr-3 pr-3 rounded"
              style="padding-bottom: 0.45rem; padding-top: 0.6rem;"
            >
              <div class="w-full flex">
                <m-placeholder
                  class="h-6 rounded-lg my-auto w-6"
                ></m-placeholder>
                <div
                  class="ml-2 flex justify-between w-full"
                  style="margin-top: -0.05rem;"
                >
                  <m-placeholder
                    class="h-6 rounded-lg my-auto w-full lg:w-1/2"
                  ></m-placeholder>
                  <div class="hidden lg:flex justify-between ml-5">
                    <m-placeholder
                      class="ml-5 h-6 rounded-lg my-auto w-32"
                    ></m-placeholder>
                    <m-placeholder
                      class="ml-4 h-6 rounded-lg my-auto w-20"
                    ></m-placeholder>
                    <div class="ml-4">
                      <m-placeholder
                        class="flex overflow-hidden rounded-full items-center justify-center relative h-6 w-6"
                      ></m-placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- loader 2  -->
            <div
              class="flex -ml-3 pl-3 -mr-3 pr-3 rounded"
              style="padding-bottom: 0.45rem; padding-top: 0.6rem;"
            >
              <div class="w-full flex">
                <m-placeholder
                  class="h-6 rounded-lg my-auto w-6"
                ></m-placeholder>
                <div
                  class="ml-2 flex justify-between w-full"
                  style="margin-top: -0.05rem;"
                >
                  <m-placeholder
                    class="h-6 rounded-lg my-auto w-full lg:w-1/2"
                  ></m-placeholder>
                  <div class="hidden lg:flex justify-between ml-5">
                    <m-placeholder
                      class="ml-5 h-6 rounded-lg my-auto w-32"
                    ></m-placeholder>
                    <m-placeholder
                      class="ml-4 h-6 rounded-lg my-auto w-20"
                    ></m-placeholder>
                    <div class="ml-4">
                      <m-placeholder
                        class="flex overflow-hidden rounded-full items-center justify-center relative h-6 w-6"
                      ></m-placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- loader 3  -->
            <div
              class="flex -ml-3 pl-3 -mr-3 pr-3 rounded"
              style="padding-bottom: 0.45rem; padding-top: 0.6rem;"
            >
              <div class="w-full flex">
                <m-placeholder
                  class="h-6 rounded-lg my-auto w-6"
                ></m-placeholder>
                <div
                  class="ml-2 flex justify-between w-full"
                  style="margin-top: -0.05rem;"
                >
                  <m-placeholder
                    class="h-6 rounded-lg my-auto w-full lg:w-1/2"
                  ></m-placeholder>
                  <div class="hidden lg:flex justify-between ml-5">
                    <m-placeholder
                      class="ml-5 h-6 rounded-lg my-auto w-32"
                    ></m-placeholder>
                    <m-placeholder
                      class="ml-4 h-6 rounded-lg my-auto w-20"
                    ></m-placeholder>
                    <div class="ml-4">
                      <m-placeholder
                        class="flex overflow-hidden rounded-full items-center justify-center relative h-6 w-6"
                      ></m-placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Loading effect -->
        </template>
      </template>

      <template v-if="defer(2)">
        <template v-if="eventDetailsFetched">
          <template v-if="filteredActions.length > 0">
            <div
              class="w-4/5 m-auto"
              v-for="(actionsGroup, key) in actionsViewNotEmpty"
              :key="key"
            >
              <div
                v-if="groupBy == 'byMeeting'"
                class="flex mt-7"
                data-recording-sensitive
              >
                <div class="truncate text-base1 font-medium text-lg">
                  <router-link
                    :to="{
                      name: 'meeting',
                      params: { id: actionsGroup.eventID },
                    }"
                  >
                    {{
                      getEventTitle(
                        eventDetails[actionsGroup.eventID].event.summary
                      )
                    }}
                  </router-link>
                </div>
                <div
                  class="ml-3 font-medium text-grey3 text-opacity-75 self-end"
                  style="min-width: fit-content;"
                  :title="
                    actionsGroup.recurring ? 'Recurring meeting series' : ''
                  "
                >
                  {{
                    actionsGroup.recurring
                      ? 'Recurring'
                      : formatMeetingDate(eventDetails[key].event)
                  }}
                </div>
              </div>
              <div v-if="groupBy == 'byOwner'" data-recording-sensitive>
                <div class="text-base1 font-medium mt-7 text-lg">
                  {{ key }}
                </div>
              </div>
              <div v-if="groupBy == 'byDate'" data-recording-sensitive>
                <div class="text-base1 font-medium mt-7 text-lg">
                  {{ key }}
                </div>
              </div>

              <hr
                class="mt-3 mb-4 border-solid border-l-0 border-r-0 border-b-0 border-base2 border-opacity-5 dark:border-base5"
              />
              <action-item-block
                v-for="action in actionsGroup.actions"
                :action="action"
                :eventDetailObject="eventDetails[action.doc_id]"
                :key="action.action_id"
                :groupBy="groupBy"
                @completedchange="updateActionCompleted($event)"
                @actiondetailshown="showActionDetail($event)"
                :showDetail="openedActionId == action.action_id ? true : false"
              >
                <div v-html="action.html"></div>
              </action-item-block>
            </div>
          </template>
          <template v-else>
            <div class="w-4/5 m-auto">
              <h4 class="text-grey2 text-base text-center">
                Oops, no actions found! Try creating some in your
                <span
                  class="underline cursor-pointer opacity-75 hover:opacity-100"
                >
                  <router-link :to="{ name: 'now' }">next meeting</router-link>
                </span>
              </h4>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'debounce';
import { getCalendarEventById } from '@/api/Google';
import {
  setPreferences,
  getUserNotesWithActions,
  subscribe,
} from '@/api/Cherry';
import { updateAction } from '@/api/Hocuspocus';
import { DOMSerializer } from 'prosemirror-model';

import EditorExtensions from '@/components/Editor2/extensions';
import { Editor } from '@tiptap/vue-2';

import ActionItemBlock from '@/components/ActionItemBlock';
import MCheckbox from '@/components/UI/MCheckbox';
import MMenuPanel from '@/components/MMenuPanel';
import MRadio from '@/components/UI/MRadio';
import UserHeader from '@/components/UserHeader';
import MPlaceholder from '@/components/UI/MPlaceholder';
import Defer from '@/mixin/Defer';

export default {
  name: 'Actions',
  components: {
    ActionItemBlock,
    MCheckbox,
    MMenuPanel,
    MRadio,
    MPlaceholder,
    UserHeader,
  },
  mixins: [Defer()],
  data() {
    return {
      openedActionId: '',
      filterHidden: 'hidden',
      groupBy: 'byDate',
      filterOwn: true,
      filterOthers: true,
      filterCompleted: false,
      filterPending: {},
      docsID: [], // all docs ID - unique
      docsIDSeparate: { private: [], public: [] },
      docs: { private: {}, public: {} }, // all docs - prose format

      eventDetails: {}, // event details from google
      eventDetailsErrorsIDs: [], // array od gIDs where error when fetching
      extractedActions: {},
      actionsExtracted: false, // all actions were extracted
      eventDetailsFetched: false,

      fetchedProfiles: {},
    };
  },

  mounted() {
    this.$scrollToTop();
    // set filters from vuex
    this.filterOwn = this.filterOwnPref;
    this.filterOthers = this.filterOthersPref;
    this.filterCompleted = this.filterCompletedPref;
    this.groupBy = this.groupByPref;

    // load all actions to variable for later
    this.loadAllMeetingIds(this.filterCompleted);
    this.checkTrialStatus();
    // filter
    // this.changeFilter();
  },
  methods: {
    resetActions() {
      this.docsID = [];
      this.docsIDSeparate = { private: [], public: [] };
      this.docs = { private: {}, public: {} };

      this.eventDetails = {};
      this.eventDetailsErrorsIDs = [];
      this.extractedActions = {};
      this.actionsExtracted = false;
      this.eventDetailsFetched = false;
      this.fetchedProfiles = {};
    },
    resetUpdateCounters() {
      // reset triggers "change" so it will refilter result
      const keys = Object.keys(this.extractedActions);
      keys.forEach((key) => {
        this.$set(this.extractedActions[key], 'update_counter', 0);
      });
    },
    async checkTrialStatus() {
      let plan = this.$store.getters['plan'];
      let era = this.$store.getters['era'];
      let trialConsumed = this.$store.getters['trialConsumed'];
      let errorMsg = 'readonly-trialended';
      let message = '';
      if (plan == 0 && era == 1 && trialConsumed) {
        await subscribe().then((r) => {
          if (r.URL) {
            message = [
              {
                link: {
                  text: 'Reactivate your Meetric account',
                  isExternal: true,
                  to: r.URL,
                },
              },
            ];
            this.$notification(message, {
              closable: false,
              type: 'warning',
              errorMsg,
            });
          } else {
            console.error('Problem finding payment URL');
          }
        });
      }
    },
    updateActionCompleted({ completed, docID, actionID, isPrivate }) {
      if (!actionID || !docID) {
        this.$snack('Error, please try to refresh the page');
        return;
      }

      const payload = {
        mid: docID,
        cid: this.eventDetails[docID]?.cid || '',
        email: this.getEmail, // my email, needed only for private note
        completed: completed,
        uuid: actionID,
        private: isPrivate,
      };

      const ai = this.extractedActions[actionID];
      this.$set(ai, 'action_pending', true);

      updateAction(payload).then((r) => {
        this.$set(ai, 'action_pending', false);

        if (!(actionID in this.extractedActions)) return;

        if (r?.success) {
          this.$set(ai, 'action_completed', completed);
          this.$set(ai, 'update_counter', ++ai.update_counter);

          const trackProps = {
            source: 'action-page',
            value: completed,
            action_uuid: actionID,
            meeting_id: docID,
            isPrivate: isPrivate,
          };
          window.meetric.track('Toggle action status', trackProps);
          return;
        }

        // ERRORS
        // change back to opposite value if error, this is
        if (r?.error?.code == 403) {
          this.$set(ai, 'update_counter', ++ai.update_counter);
          this.$snack('Authentication fail, please try to refresh the page');
        } else if (r?.error?.code == 404) {
          this.$delete(this.extractedActions, actionID);
          this.$snack(
            'Oops! We could not find that action. Please refresh the page to reload all actions'
          );
        } else if (r?.error) {
          this.$set(ai, 'update_counter', ++ai.update_counter);
          this.$snack('Error, please try to refresh the page');
        }
      });
    },
    fetchProfile(email) {
      if (email in this.fetchedProfiles) return;
      if (this.$store?.getters.profile(email)) return;

      this.$store?.dispatch('fetchProfile', email);
      this.fetchedProfiles[email] = true;
    },
    showActionDetail(event) {
      if (this.openedActionId == event) {
        this.openedActionId = '';
      } else {
        this.openedActionId = event;
      }
    },
    toggleFilters() {
      this.filterHidden = this.filterHidden === 'hidden' ? 'flex' : 'hidden';
    },

    getEventTitle(summary) {
      return summary ? summary : '(No title)';
    },

    // When filter or groupBy is changed
    changeGroupBy(newValue, changedFromToggle = true) {
      this.groupBy = newValue;

      if (changedFromToggle && this.groupBy != this.groupByPref) {
        // save change to profile
        this.setPreference('actions_groupby', newValue);
      }
    },
    // When you change filter, preserve groupBy as it was
    changeFilter(type, val) {
      if (type == 'completed') {
        // if DONE filter changed, reset update counters so the items are properly re-filtered
        this.resetUpdateCounters();
        this.resetActions();
        this.loadAllMeetingIds(this.filterCompleted);
      }
      this.changeGroupBy(this.groupBy, false);

      this.setPreference(`actions_filter_${type}`, val);
    },

    setPreference(label, value) {
      var data = {};
      data[label] = value;

      this.filterPending[label]?.clear();
      this.filterPending[label] = debounce(
        () => this.setPreferenceRemote(data, label),
        1000
      );
      this.filterPending[label]();
    },
    setPreferenceRemote(data, label) {
      setPreferences(data).then((result) => {
        // if (this.filterPending[type]) this.filterPending[type] = false;
        this.$store.commit('savePreferences', result);
        this.updateFilter(label);
      });
    },
    updateFilter(label) {
      // update just specific preference to avoid overwritting
      // in case of multiple changes as server returns all preferences
      if (label == 'actions_filter_own') this.filterOwn = this.filterOwnPref;
      else if (label == 'actions_filter_others')
        this.filterOthers = this.filterOthersPref;
      else if (label == 'actions_filter_completed')
        this.filterCompleted = this.filterCompletedPref;
      else if (label == 'actions_groupby') this.groupBy = this.groupByPref;
    },
    loadAllMeetingIds(includeCompleted) {
      //  get all the docs
      // const _this = this;
      getUserNotesWithActions(includeCompleted).then((data) => {
        data.notes_with_actions.forEach(({ info, doc }) => {
          if (!info) return;

          if (info.private) {
            this.docsIDSeparate.private.push(info.meetingId);
            this.docs.private[info.meetingId] = doc;
          } else {
            this.docsIDSeparate.public.push(info.meetingId);
            this.docs.public[info.meetingId] = doc;
          }
        });
        // save only unique values as some ID can have both public and private
        this.docsID = [
          ...new Set([
            ...this.docsIDSeparate.private,
            ...this.docsIDSeparate.public,
          ]),
        ];
        this.docsIDFetched = true;
        this.loadGoogleEventsDetails();
      })
      .catch(() => {
        console.error("Notes not found")
        this.docsIDFetched = true;
        this.loadGoogleEventsDetails();
      });
    },
    loadGoogleEventsDetails() {
      // const _this = this;
      Promise.all(
        this.docsID.map(async (eventID) =>
          getCalendarEventById(this.calendarIds, eventID)
        )
      ).then((responses) => {
        // return first valid
        for (let i = 0; i < responses.length; i++) {
          if (responses[i]) {
            this.$set(this.eventDetails, this.docsID[i], responses[i]);
          } else {
            this.eventDetailsErrorsIDs.push(this.docsID[i]);
          }
        }
        // this.initializeAllEditors();
        this.eventDetailsFetched = true;
        this.extractActions();
      });
    },
    extractActions() {
      let editor = new Editor({
        created: Date.now(),
        meetingId: '',
        isPrivate: false,
        inRelatedMeeting: false,
        extensions: EditorExtensions({
          showSuggestionList: false,
          dateFormat: 'MM/DD/YYYY',
        }),
        editable: false,
      });

      const extract = ({ docID, isPrivate, doc }) => {
        try {
          editor.commands.setContent(doc);
        }
        catch (err) {
          console.error(err)
          return
        }

        editor.state.doc.descendants((node) => {
          // only top level nodes
          if (node.type?.name === 'action_item') {
            // console.log('actionItem found', node, node.attrs.uuid);
            // empty action_item has size of 2, don't show empty ones
            if (node.content?.size > 2) {
              const duedate = node.attrs.dueDate ? node.attrs.dueDate : '';
              const owner_email = node.attrs.owner ? node.attrs.owner : '';
              if (owner_email) this.fetchProfile(owner_email);

              if (
                (!this.filterCompleted && !node.attrs.completed) ||
                (this.filterCompleted && node.attrs.completed)
              ) {
                this.$set(this.extractedActions, node.attrs.uuid, {
                  action_owner_email: owner_email,
                  action_duedate: duedate,
                  update_counter: 0,
                  action_pending: false,
                  doc_id: docID,
                  private: isPrivate,
                  action_id: node.attrs.uuid,
                  node: node,
                  html: DOMSerializer.fromSchema(
                    node.type.schema
                  ).serializeNode(node).innerHTML,
                  action_completed: node.attrs.completed,
                });
              }
            }
          }
        });
      };

      // public
      this.docsIDSeparate.public.forEach((docID) => {
        //console.log(docID)
        extract({ docID, isPrivate: false, doc: this.docs.public[docID] });
      });

      //private
      this.docsIDSeparate.private.forEach((docID) => {
        //console.log(docID)
        extract({ docID, isPrivate: true, doc: this.docs.private[docID] });
      });

      editor.destroy();
      this.actionsExtracted = true;
    },

    // View by: Owner
    groupByOwner(filteredActions) {
      const _this = this;

      // filtered now so we iterate only items we want to show
      const unsorted = filteredActions.reduce(function (obj, item) {
        let key = null;
        if (item.action_owner_email) {
          key = _this.$store.getters.getName(item.action_owner_email);
        } else {
          key = 'None';
        }

        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = { actions: [] };
        }
        obj[key].actions.push(item);

        return obj;
      }, {});

      function sortCaseInsensitive(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }

      // if "None" key exists, extract it so it is not part of sorting
      let noneKey = null;
      if (unsorted['None'] !== undefined) {
        noneKey = unsorted['None'];
        delete unsorted['None'];
      }

      let sorted = {};
      // sort case-insensitive by name/email
      Object.keys(unsorted)
        .sort(sortCaseInsensitive)
        .forEach(function (key) {
          sorted[key] = unsorted[key];
        });

      // insert "None" key back after sort if exists so it is at the end
      if (noneKey !== null) {
        sorted['None'] = noneKey;
      }

      return sorted;
    },
    // View by: Due date
    groupByActionDate(filteredActions) {
      let dueCategory = {
        Overdue: { actions: [] },
        Today: { actions: [] },
        Tomorrow: { actions: [] },
        Later: { actions: [] },
        None: { actions: [] },
      };

      const today = new Date();
      today.setHours(0, 0, 0, 0); // midnight, ignore time
      const oneDay = 1000 * 60 * 60 * 24; // millisecond

      for (let i = 0; i < filteredActions.length; i++) {
        const action = filteredActions[i];
        if (action.action_duedate == null) {
          dueCategory['No dates'].actions.push(action);
          continue;
        }
        const justDate = action.action_duedate.split('T')[0];
        const dueDate = new Date(justDate + ' 00:00');
        const difference = (dueDate - today) / oneDay;

        if (difference < 0) {
          dueCategory['Overdue'].actions.push(action);
        } else if (difference === 0) {
          dueCategory['Today'].actions.push(action);
        } else if (difference === 1) {
          dueCategory['Tomorrow'].actions.push(action);
        } else if (!justDate) {
          dueCategory['None'].actions.push(action);
        } else {
          dueCategory['Later'].actions.push(action);
        }
      }

      return dueCategory;
    },
    // View by: Meeting
    groupByMeetings(filteredActions) {
      const _this = this;

      function sortByMeetingDateDesc(a, b) {
        // descending, comparing dates
        return new Date(b[1]) - new Date(a[1]);
      }
      function sortByActionDateAsc(a, b) {
        if (a.action_duedate === '') return 1;
        else if (b.action_duedate === '') return -1;

        return new Date(a.action_duedate) - new Date(b.action_duedate);
      }

      let filteredMeetings = filteredActions.reduce(function (obj, item) {
        const key = item.doc_id;
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }
        obj[key].push(item);

        return obj;
      }, {});

      // can't sort object directly, have to create array
      let sortable = [];
      Object.keys(filteredMeetings).forEach(function (key) {
        if (_this.eventDetails[key] && _this.eventDetails[key].event) {
          sortable.push([
            key,
            _this.getDatetimeFromEvent(_this.eventDetails[key].event),
          ]);
        }
      });

      // use sorted array as an index to get sorted object
      let sortedMeetings = {};
      sortable
        .sort(sortByMeetingDateDesc)
        .map((x) => (sortedMeetings[x[0]] = filteredMeetings[x[0]]));

      let groupedMeetings = {};
      // meetings sorted DESC, actions ASC, now put recurring together
      Object.keys(sortedMeetings).forEach(function (key) {
        const eventID = key.split('_')[0];
        if (!Object.prototype.hasOwnProperty.call(groupedMeetings, eventID)) {
          // setup the key as an array so you can push results
          groupedMeetings[eventID] = { recurring: false, actions: [] };
        }
        groupedMeetings[eventID].eventID = key; // full one
        if (key != eventID) {
          // not same so the ID is recurring
          groupedMeetings[eventID].recurring = true;
          sortedMeetings[key].forEach((item) => {
            groupedMeetings[eventID].actions.push(item);
          });
        } else {
          // same ID, not recuring
          groupedMeetings[eventID].actions = sortedMeetings[key];
        }
      });
      // I hate it but have to loop through again to sort correctly actions within a meeting
      Object.keys(groupedMeetings).forEach((key) => {
        groupedMeetings[key].actions.sort(sortByActionDateAsc);
      });

      return groupedMeetings;
    },
    // use datetime if present or date if not
    getDatetimeFromEvent: function (event) {
      if (event.start.dateTime) {
        return event.start.dateTime;
      } else if (event.start.date) {
        return event.start.date;
      }
      return '';
    },
    formatMeetingDate: function (event) {
      return moment(this.getDatetimeFromEvent(event)).format('ddd, MMM D');
    },
  },
  computed: {
    getEmail() {
      return this.$gAuth?.basicProfile?.getEmail() || '';
    },
    groupByPref() {
      return this.$store.getters['preferenceActionsGroupBy'];
    },
    filterOwnPref() {
      return this.$store.getters['preferenceActionsFilterOwn'];
    },
    filterOthersPref() {
      return this.$store.getters['preferenceActionsFilterOthers'];
    },
    filterCompletedPref() {
      return this.$store.getters['preferenceActionsFilterCompleted'];
    },

    calendarIds() {
      return this.$store.getters['selectedCalendars'];
    },

    // hide all categories with no children
    actionsViewNotEmpty: function () {
      const keys = Object.keys(this.actionsView);
      let result = {};
      keys.forEach((key) => {
        if (this.actionsView[key].actions.length > 0)
          result[key] = this.actionsView[key];
      });
      return result;
    },

    actionsView: function () {
      const filtered = this.filteredActions;

      if (this.groupBy == 'byDate') return this.groupByActionDate(filtered);
      else if (this.groupBy == 'byOwner') return this.groupByOwner(filtered);
      else if (this.groupBy == 'byMeeting')
        return this.groupByMeetings(filtered);

      return {};
    },

    filteredActions() {
      const _this = this;
      const keys = Object.keys(this.extractedActions);
      let filtered = [];

      keys.forEach((key) => {
        filtered.push(this.extractedActions[key]);
      });

      return filtered
        .filter(
          (action) =>
            // if update_counter>0 ignore filter because action was updated and we wanna see it
            action.update_counter > 0 ||
            !(!_this.filterCompleted && action.action_completed)
        )
        .filter(
          (action) =>
            (action.action_owner_email ===
              _this.$gAuth.basicProfile.getEmail() &&
              _this.filterOwn) ||
            (action.action_owner_email !==
              _this.$gAuth.basicProfile.getEmail() &&
              _this.filterOthers)
        )
        .sort(sortByActionDateAsc);
    },
  },
  beforeDestroy() {},
};

function sortByActionDateAsc(a, b) {
  if (a.action_duedate === '' || a.action_duedate === null) return 1;
  else if (b.action_duedate === '' || b.action_duedate === null) return -1;

  return new Date(a.action_duedate) - new Date(b.action_duedate);
}
</script>
